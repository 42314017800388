
<template>
  <div class="Family_B">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect_top">
        <el-menu-item index="1">企业公告</el-menu-item>
        <el-menu-item index="2">内部资料</el-menu-item>
        <el-menu-item index="3">协同办公</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：用于内部通知，使用场景如：通知、求助、任务、因为数据内部管理数据所以安全性比企业微信群更可靠
      </div>
    </div>
    <div style="margin-bottom:30px;overflow: hidden;">
      <div class="Table_left Tabless">
          <div class="custom-tree-container">
            <div class="block" style="margin-top:6px">
              <div class="GGixf">共享资料</div>
              <div class="boopl" @click="AuAxAb(0)">
                <img src="../../assets/img/immm.png" alt="">
                <span>/</span>
              </div>
              <div class="boopl" v-for="(item,i) in FolderList" :key="i" @click="AuAxAb(item.id)">
                <img src="../../assets/img/immm.png" alt="">
                <span>{{item.name}}</span>

                <div @click.stop style="margin-left: 150px;float: right;margin-top: -13px;">
                  <el-dropdown trigger="click" @click="More(item.id)" style="margin-rignt:0">
                    <i class="Rund el-icon-more"></i>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item command="a" @click.stop="bianji(item,node)">编辑</el-dropdown-item>
                        <!-- <el-dropdown-item command="a" @click.stop="append(item,node)">添加</el-dropdown-item> -->
                        <el-dropdown-item command="b"  @click.stop="remove22(item, data)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div> 

              </div>
            </div>
          </div>
      </div>
      <div class="Tabless2" style="width:79%;min-height:597px">
        <div style="overflow:hidden">
          <div class="Tab_T" @click="Add_kq(1)" v-if="TextMoMo('workbench:disk:folder:add')"> <i class="el-icon-plus"></i> 创建</div>
          <div class="Tab_T fdgdfg" @click="Add_kq(2)" style="background:#FFFFFF;border:1px solid #D9D9D9" v-if="TextMoMo('workbench:disk:file:upload')">
            <el-upload
              :action="upload"
              :headers="heads"
              :limit="1"
              :data = "pid_obj"
              :multiple="false"
              :on-success="UP_Success3"
              :on-remove="fileRemove">
              <span style="color:#595959">上传</span>
            <img style="width: 18px; height: 18px" src="../../assets/img/+++.png" />
                </el-upload>
                
              
          </div>
          <div class="iyiyiy">
            <div :class="iyiy == 1 ? 'iy_class' : ''">
              <img style="width: 18px; height: 18px;padding-left:7px" src="../../assets/img/lef_Ch.png"  alt="" @click="Iuiiu(1)">
            </div>
            <span></span>
            <div :class="iyiy == 2 ? 'iy_class' : ''">
              <img style="width: 18px; height: 18px;padding-left:7px" src="../../assets/img/rig_Ch.png.png" alt="" @click="Iuiiu(2)">
            </div>
          </div>
          <div class="Tab_T" @click="Add_kp" style="background:#ffffff"></div>
          <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
          <div class="dsds">
            <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()" style="width:20%;margin-top: -4px;"></el-input>
          </div>
        </div>
        <div style="overflow:hidden;margin-bottom: 20px;">
          <div class="Tioasd" @click="Niex(item.id)"  v-for="(item,i) in Parents" :key="i">
            <span>{{item.name}}</span>
            <span v-if="Parents.length > 1">/</span>
          </div>
        </div> 
        <div v-if="iyiy == 2" class="Filiil" >
          <div class="fiooi">
            <div v-for="(item,i) in FileList" :key="i" @click="handles(item)">
              <img v-if="item.type == 'folder'" src="../../assets/img/imm2.png" alt="" @click.stop="AuAxAb(item.id)">
              <img v-else-if="item.format == 'doc' || item.format == 'docx'"  src="../../assets/img/word2.png" alt="">
              <img v-else-if="item.format == 'pdf'" src="../../assets/img/pdf2.png" alt="">
              <img class="Toper" v-else-if="item.format == 'ppt' || item.format == 'pptx'" src="../../assets/img/ppt2.png" alt="">
              <img class="Toper" v-else-if="item.format == 'xls' || item.format == 'xlsx'" src="../../assets/img/xlsx2.png" alt="">
              <img class="Toper" v-else-if="item.format == 'zip' || item.format == 'rar'" src="../../assets/img/zip2.png" alt="">
              <img class="Toper" v-else-if="item.format == 'jpg' || item.format == 'png' || item.format == 'bmp'|| item.format == 'jpeg' || item.format == 'gif'" :src="item.file_url" alt="">
              <img class="Toper" v-else src="../../assets/img/qita2.png" alt="">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
        <div v-if="iyiy == 1" class="hgjghj">
          <div style="height:552px;width: 100%;z-index:999;" >
            <el-scrollbar style="height:100%">
              <el-table
                :data="FileList"
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
                <el-table-column label="公告名称" width="80">
                    <template #default="scope" >
                      <img class="Toper" v-if="scope.row.type == 'folder'" @click="AuAxAb(scope.row.id)" src="../../assets/img/a25ce5046f438457163a9e15c5d1157.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'doc' || scope.row.format == 'docx'" src="../../assets/img/word.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'pdf'" src="../../assets/img/pdf.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'ppt' || scope.row.format == 'pptx'" src="../../assets/img/ppt.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'xls' || scope.row.format == 'xlsx'" src="../../assets/img/xlsx.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'zip' || scope.row.format == 'rar'" src="../../assets/img/zzzip.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'jpg' || scope.row.format == 'png' || scope.row.format == 'bmp'|| scope.row.format == 'jpeg' || scope.row.format == 'gif'" src="../../assets/img/jpg.png" alt="">
                      <img class="Toper" v-else src="../../assets/img/qita.png" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="" width="480">
                  <template #default="scope">
                    <div style="cursor: pointer;" v-if="scope.row.type == 'folder'" @click="AuAxAb(scope.row.id)">{{scope.row.name}}</div>
                    <div v-if="scope.row.type != 'folder'">{{scope.row.name}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="staff.realname" label="更新者" width="120"></el-table-column>
                <el-table-column prop="filesize" label="大小" width="120"></el-table-column>
                <el-table-column prop="createtime" label="最近更新" width="150"></el-table-column>
                <el-table-column label="操作" align="center" >
                  <template #default="scope" >
                      <div class="el_butto" >
                        <el-button type="text" @click="handles(scope.row)" v-if="scope.row.type != 'folder'">查看</el-button>
                      </div>
                      <div class="el_butto" @click.stop  v-if="TextMoMo('workbench:disk:folder:edit')">
                        <el-button type="text" @click="bianji3(scope.row)">修改名称</el-button>
                      </div>
                      <div class="el_butto rere" >
                        <el-button v-if="TextMoMo('workbench:disk:folder:del')" type="text" class="red" @click="HanDel(scope.row)">删除</el-button>
                      </div>
                  </template>
                </el-table-column>
              </el-table>   
            </el-scrollbar>
          </div>
        </div>
      </div> 
    </div>
    <!--新建文件夹-->
    <el-dialog title="新建文件夹" v-model="dialogVisible1" width="24.5%">
      <div class="fdgfhhs">
        <div style="margin-bottom:10px">输入文件夹名称：</div>
        <el-input v-model="diasible9" placeholder="" ></el-input>
      </div>
      <div class="Dityo">
        <div class="dialog-footer" style="padding-right:28px">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="Btn_xiugai" style="margin-left:15px">确 认</el-button>
        </div>
      </div>  
    </el-dialog>
    <!--新建文件夹-->  

    <!--新建文件夹-->
    <el-dialog title="修改文件夹" v-model="dialogVisible11" width="24.5%">
      <div class="fdgfhhs">
        <div style="margin-bottom:10px">输入文件夹名称：</div>
        <el-input v-model="diasible99" placeholder="" ></el-input>
      </div>
      <div class="Dityo">
        <div class="dialog-footer" style="padding-right:28px">
          <el-button @click="dialogVisible11 = false">取 消</el-button>
          <el-button type="primary" @click="Btn_xiugai11" style="margin-left:15px">确 认</el-button>
        </div>
      </div>  
    </el-dialog>
    <!--新建文件夹-->  

    <!--登录-->
    <el-dialog title="前往登录" v-model="dialogVisible5" width="24.5%">
      <div class="Dityo">
        <div class="dialog-footer" style="padding-right:28px">
          <el-button @click="dialogVisible5 = false">取 消</el-button>
          <el-button type="primary" @click="Btn_xiugai222" style="margin-left:15px">确 认</el-button>
        </div>
      </div>  
    </el-dialog>
    <!--登录-->  
  </div>
</template>

<script>
import axios from 'axios' 
import  TextMoth  from "../../components/utils.vue";
export default {
  name: "basetable",
  data() {
    return {
      Parents:[],
      FolderList:[],
      FoList:[],
      FileList:[],
      fileList2:[],
      File:[],

      DMent:[],
      DMent2:[],
      tag_obj:[],
      Fond_tag:null,
      dialogVisible1:false,
      dialogVisible11:false,
      dialogVisible5:false,
      redirect_uri:'',
      diasible9:'',
      diasible99:'',
      
      upload:'https://rest-api.duxin365.com/admin/workbench/disk/upload',
      heads:{token:''},
      MMD:[],
      iyiy: 1,
      pid:0,
      pid_obj:{folder_id:0},
      input_seach:'',
      D_input:'',
      activeIndex: "2",
      more:'../../assets/img/more.png',
      input: "",
      num:'',

      total:0,
      total1:0,
      XX:0,
      Bianj:'',
      asdasd:'asd',
      pid2:0
    };
  },
  mounted: function() {
    var that = this

        let token = window.localStorage.getItem("accessToken")
        that.heads.token = token
        axios.get('/admin/workbench/disk/folderList',{
          }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else{ 
              if (res.data.data.is_login == false) {
                //  that.$router.push({path:res.data.data.redirect_uri,query:{setid:123456}});
                console.log(res.data.data.redirect_uri)
                that.dialogVisible5 = true
                that.redirect_uri = res.data.data.redirect_uri
                //  
              }
              that.FolderList = res.data.data.folderList
            }
        })
        axios({
          method: 'post',
          url: '/admin/workbench/disk/fileList',
          data: {},
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.FileList = res.data.data.fileList
            }
        }) 
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Handlbjjt() {
      console.log('123')
    },
    Btn_xiugai222() {
      var that = this
      var arr = window.location.href
      window.location.href = that.redirect_uri + '?frontend=' + arr
    },
    handles(row,column,cell,event) {
      console.log(row)
      // window.location.href = row.file_url
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/disk/checkFile', 
        data: {
          id:row.id
        },
      }).then(function (res) {
        if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
        } else { 
        }
      })  
      window.open(row.file_url, '_blank');
    },
    File_Sure(e) {                          //文件导入确认
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/disk/upload', 
        data: {
          pid:that.pid,
          file: that.File
        },
      }).then(function (res) {
        if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
        } else{ 
          that.$message.success(res.data.msg);
                that.AuAxAb(that.pid)
                that.dialogVisible2 = false
        }

      })      
    },
    UP_Success3(e) {            
      var that = this
      axios({
                method: 'post',
                url: '/admin/workbench/disk/fileList',
                data: {
                  folder_id:  that.pid
                },
              }).then(function (res) {
                  if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
                  } else {
                    that.FileList = res.data.data.fileList
                    that.Parents = res.data.data.parents
                  }
              })  
    },
    fileRemove(file, fileList) {                    // 移除选择的文件
        if(fileList.length < 1) {
            this.uploadDisabled = true //未选择文件则禁用上传按钮
        }
        console.log(file)
    },
    Btn_xiugai(e) {                         //新建文件夹确认
      var that = this
      if (that.XX == 1) {
        axios({
          method: 'post',
          url: '/admin/workbench/disk/addFolder', 
          data: {
            row:{
              pid:that.pid,
              name: that.diasible9
            }
          },
        }).then(function (res) {
              if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                that.$message.success(res.data.msg);

                if (that.pid == 0) {
                  axios.get('/admin/workbench/disk/folderList',{ 
                    }).then(function (res) {
                      if (res.data.code == 0) {
                            that.$message.warning(res.data.msg);
                      } else{ 
                        that.dialogVisible1 = false
                        that.FolderList = res.data.data.folderList
                      }
                  })
                } else {
                  that.AuAxAb(that.pid)
                  that.dialogVisible1 = false
                }

              }
          

        }) 
      } else if (that.XX == 2) {
        axios({
          method: 'put',
          url: '/admin/workbench/disk/editFolder', 
          data: {
              id:that.Bianj.id,
              name: that.diasible9
          },

        }).then(function (res) {
              if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                that.$message.success(res.data.msg);

                if (that.pid == 0) {
                  axios.get('/admin/workbench/disk/folderList',{ 
                    }).then(function (res) {
                      if (res.data.code == 0) {
                            that.$message.warning(res.data.msg);
                      } else { 
                        that.dialogVisible1 = false
                        that.FolderList = res.data.data.folderList
                      }
                  })
          axios.get('/admin/workbench/disk/folderList',{ 
            }).then(function (res) {
              if (res.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                that.FolderList = res.data.data.folderList
              }
            })
                } else {
                  that.AuAxAb(that.pid)
                  that.dialogVisible1 = false
                }

              }
          

        })         
      } else if (that.XX == 3) {
        axios({
          method: 'put',
          url: '/admin/workbench/disk/editFolder', 
          data: {
              id:that.Bianj.id,
              name: that.diasible9
          },

        }).then(function (res) {
              if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                that.$message.success(res.data.msg);

                if (that.pid == 0) {

                } else {
                  that.AuAxAb(that.pid)
                  that.dialogVisible1 = false
                }

              }
        })         
      } else if (that.XX == 4) {
        axios({
          method: 'put',
          url: '/admin/workbench/disk/editFile', 
          data: {
              id:that.Bianj.id,
              name: that.diasible9
          },
        }).then(function (res) {
              if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                that.$message.success(res.data.msg);
              axios({
                method: 'post',
                url: '/admin/workbench/disk/fileList',
              }).then(function (res) {
                  if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
                  } else {
                    that.dialogVisible1 = false
                    that.FileList = res.data.data.fileList
                  }
              }) 

              }
        }) 
      }

    },
    Btn_xiugai11(e) {                         //修改文件夹确认
      var that = this
        axios({
          method: 'put',
          url: '/admin/workbench/disk/editFolder', 
          data: {
              id:that.pid2,
              name: that.diasible99
          },
        }).then(function (res) {
              if (res.data.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                that.$message.success(res.data.msg);
                that.dialogVisible11 = false
                axios.get('/admin/workbench/disk/folderList',{
                  }).then(function (res) {
                    if (res.code == 0) {
                      that.$message.warning(res.data.msg);
                    } else{ 
                      that.FolderList = res.data.data.folderList
                    }
                })
              }
          

        }) 


    },
    Add_kq(e) {                             // 新建  上传
      var that = this
      if (e == 1) {
        that.dialogVisible1 = true
        that.diasible9 = ''
        that.XX = 1 
      } else if (e == 2) {
        that.dialogVisible2 = true
      }
    },
    Iuiiu(e) {
      var that = this
      that.iyiy = e
    },
    inpu_sech() {             // 搜索
      var that = this
      axios({
        method: 'post',
        url: '/admin/customer/contract/index', 
        data: {
          filter:{
            keyword:that.input_seach
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })   
    },
    Niex(e) {
      var that = this
      that.AuAxAb(e)
    },
    AuAxAb(e) {
      console.log(e)
      var that = this
      that.pid = e
      that.pid_obj = {
        folder_id:e
      }
        axios({
          method: 'post',
          url: '/admin/workbench/disk/fileList',
          data: {
            folder_id: e
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.FileList = res.data.data.allList
              that.FoList = res.data.data.folderList
              that.Parents = res.data.data.parents
            }
        })       
    },

    remove(node, data) {  // 部门删除
      var that = this
      that.dia_DEl = true
      that.del_idw = 'Ment'
      that.Remov_id = data.id
    },
    bianji(e, data) {            //编辑
      console.log(e)
      console.log(data)
      var that = this
      that.pid2 = e.id
      that.dialogVisible11 = true
      that.diasible99 = e.name
    },

    remove22(e, data) {            // 删除文件
        var that = this
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          
          axios({
            method: 'delete',
            url: '/admin/workbench/disk/delFolder',
            data: {
              id: e.id
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                axios.get('/admin/workbench/disk/folderList',{
                  }).then(function (res) {
                    if (res.code == 0) {
                      that.$message.warning(res.data.msg);
                    } else{ 
                      that.FolderList = res.data.data.folderList
                    }
                })
                }
          }) 
        })
    },
    bianji3(e) {            //编辑
      console.log(e)
      var that = this
      that.dialogVisible1 = true
      that.XX = 4
      that.Bianj = e
      that.diasible9 = e.name
    },
    HanDel(e) {            // 删除
        var that = this

        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/workbench/disk/delFile',
            data: {
              id: e.id
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                axios({
                  method: 'post',
                  url: '/admin/workbench/disk/fileList',
                }).then(function (res) {
                    if (res.data.code == 0) {
                      that.$message.warning(res.data.msg);
                    } else {
                      that.dialogVisible1 = false
                      that.FileList = res.data.data.fileList
                    }
                }) 
                }
          }) 
        })
    },
    handleSelect_top (e) {
      if (e == 1) {
        this.$router.push('/Workbench/Family_A');
      }else if (e == 2) {
        this.$router.push('/Workbench/Family_B');
      } else if (e == 3) {
        this.$router.push('/Workbench/Family_C');
      }
    },

    D_sech() {                //搜索部门
        var that = this
        axios.get('/admin/company/department/index',{
            params:{
                keyword: that.D_input
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DMent = res.data.data.rows
            }
        })
    },
    formatDate(value) {    // 时间戳
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;

        return y + "-" + MM + "-" + d
    },
  },

};
</script>


<style scoped>
.Family_B .Rund {
  cursor: pointer;
  transform: rotate(90deg);
  z-index: 999;   
} 
.Family_B .Peop {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1890FF!important;
  float: right;
  margin-right: 30px;
  cursor: pointer;
}
.Family_B .Tabless {
  width: 72%;
  float: right;
  background: #fff;
  margin: 10px 24px 30px 0;
  padding: 24px;
  padding-bottom: 0;
}
.Family_B .Table_left {
    float: left!important;
    width: 11.5%!important;
    height: auto;
    min-height: 655px!important;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 10px 0px 0 24px!important;
}
.Family_B .Tabless .dsds .el-input__inner{
  width: 239px;
  height: 32px;
  float: right;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Family_B .Tabless .dsds .el-input{
  float: right;
  width: 30%;
}
.Family_B .Tabless .el_seso{
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  cursor: pointer;
}
.Family_B .Tabless .el_seso i{
  color: #8c8c8c;
}   
.Family_B .Dityo .el-button {
  min-height: 34px;
  padding: 8px 20px;
  margin-left: 25px;
}    
.Family_B .boopl {
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.Family_B .boopl img {
  width: 13px;
  height: 13px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
}
.Family_B .boopl span {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
}
.Family_B .boopl i{
  float: right;
  margin-top: -18px;
}
.Family_B .GGixf {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-bottom: 16px;
} 
.Family_B .Tioasd {
  cursor: pointer;
  font-size: 14px;
  float: left;
}
.Family_B .Tioasd span {
  margin: 0 5px 0 0;
}
.Family_B .iyiyiy {
  width: 64px;
  height: 32px;
  float: left;
  border: 1px solid #D9D9D9;
  border-radius: 4px 4px 4px 4px;
}
.Family_B .iyiyiy div {
  float: left;
  width: 50%;
}
.Family_B .iyiyiy img {
  width: 49.8%;
  text-align: center;
  padding-top: 8px;
  float: left;
  cursor: pointer;
  height: 77%;
} 
.Family_B  .fiooi {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

}
.Family_B  .fiooi div {
  width: 90px;
  margin-right: 50px;
  margin-bottom: 20px;
}
.Family_B  .fiooi div:nth-child(9) {
  margin: 0;
}
.Family_B  .fiooi div img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
.Family_B .fiooi div span {
  font-size: 14px;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
  width: 76px;
  margin-left: 0;
}
.Family_B .iy_class {
  background: #D9D9D9;
  width: 50%;
  height: 100%;
} 
.Family_B .hgjghj img {
  width: auto;
  height: 40px;
  cursor: pointer;
} 
.Family_B .boopl .el-dropdown {
  position: absolute;
  right: 0;
}
.Family_B .Tabless2 {
  width: 1242px;
  float: right;
  height: auto;
  background: #fff;
  margin-right: 24px;
  margin-top: 10px;
  padding: 24px;
  overflow: hidden;
  border-radius: 4px;
}
.Family_B .Tabless2 .el-image {
      float: left;
    margin-left: 13px;
    margin-top: 9px;
} 
.Family_B .Tabless2 .dsds .el-input__inner {
    height: 32px;
    float: right;
    line-height: 32px;
    width: 343px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.Family_B .dsds .el-input {
    float: right;
    width: 30%;
}
.Family_B /deep/ .el-upload--text {
    background-color: #fff;
    border:0 !important;
    border-radius: 0 !important;
    box-sizing: border-box;
    width: 30px !important;
    height: 16px !important;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.Family_B .dasrttr .el-input {
  width: 220px;
}

</style>
